import Link from "next/link";

import { useI18n } from "utils/with-i18n.utils";

import { FooterLink } from "../../FooterLink";

export const SupportHelp = () => {
  const i18n = useI18n();

  return (
    <section className="flex flex-col items-center mt-9 space-y-5 lg:items-start lg:my-0">
      <h2 className="mb-2 text-lg font-semibold">
        {i18n.t("footer.supporthelp.title")}
      </h2>
      <ul className="space-y-2 list-none">
        <li>
          <FooterLink href="https://aide.mobile.club/" target="_blank">
            {i18n.t("footer.company.helpcenter")}
          </FooterLink>
        </li>
        <li>
          <Link href="/espace-membre" passHref={true}>
            <FooterLink>{i18n.t("footer.customers.account")}</FooterLink>
          </Link>
        </li>
        <li>
          <FooterLink
            href="https://www.welcometothejungle.com/fr/companies/mobile-club"
            target="default"
          >
            {i18n.t("footer.company.career")}
          </FooterLink>
        </li>
        <li>
          <Link href="/questions" passHref={true}>
            <FooterLink>{i18n.t("footer.faq")}</FooterLink>
          </Link>
        </li>
        <li>
          <FooterLink
            href="https://aide.mobile.club/article/102-nos-conditions-de-retractation"
            target="_blank"
          >
            {i18n.t("footer.company.withdrawal")}
          </FooterLink>
        </li>
      </ul>
    </section>
  );
};
